import i18n from "i18next";
import {initReactI18next} from "react-i18next";

const de = require("./i18n/i18n_languages/de.json");
const en = require("./i18n/i18n_languages/en.json");
const es = require("./i18n/i18n_languages/es.json");


const resources = {
    en,
    de,
    es
};


i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        // "de" as default language but will be automatically changed depending on the user settings
        lng: "de",
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });


export default i18n;

